import React, { FC } from "react";
import { Footer, FooterProps } from "./footer";

export const FooterContainer: FC = () => {
  const props: FooterProps = {
    social: [
      {
        icon: "fa-facebook-f",
        link: "https://www.facebook.com/govmatogrosso",
      },
      {
        icon: "fa-twitter",
        link: "https://twitter.com/GovMatoGrosso",
      },
      {
        icon: "fa-instagram",
        link: "https://www.instagram.com/govmatogrosso",
      },
      {
        icon: "fa-youtube",
        link: "https://www.youtube.com/@secom_mt",
      },
    ],
    lists: [
      {
        title: "Imprensa",
        linkList: [
          // {
          //   text: "Sala de Imprensa",
          //   link: "http://www.mt.gov.br/imprensa",
          // },
          {
            text: "Notícias",
            link: "https://www.secom.mt.gov.br/inicio",
          },
          {
            text: "Rádio Paiaguás",
            link: "https://www.secom.mt.gov.br/radio-paiaguas",
          },
          {
            text: "TV Paiaguás",
            link: "https://www.secom.mt.gov.br/tv-paiaguas",
          },
          {
            text: "Fotos",
            link: "https://www.secom.mt.gov.br/fotos",
          },
        ],
      },
      {
        title: "Sites Institucionais",
        linkList: [
          {
            text: "Secretarias do Governo",
            link: "/app/catalog/orgao",
          },
          {
            text: "Portal de Dados de Mato Grosso",
            link: "https://dados.seplag.mt.gov.br/",
          },
        ],
      },
      {
        title: "Transparência",
        linkList: [
          {
            text: "Portal de Transparência",
            link: "http://www.transparencia.mt.gov.br/",
          },
          {
            text: "Acesso à Informação",
            link: "https://www.cge.mt.gov.br/acesso-a-informacao",
          },
          {
            text: "Radar da Transparência Pública",
            link: "https://radar.tce.mt.gov.br/extensions/atricon2/atricon2.html",
          },
        ],
      },
    ],
  };

  return <Footer {...props} />;
};
