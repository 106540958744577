import React, { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card";
import { Button, Col, Row, Spin } from "antd";
import { Container } from "../../components/container/container";
import { Carrocel } from "../../components/carousel/carrocel";
import { BreakPage } from "../../components/break-page/break-page";

export interface RecommendedServicesProps {
  services?: ServiceCardProps[];
  clickMore?: () => any;
}

export const RecommendedServices: FC<RecommendedServicesProps> = ({
  services,
  clickMore,
}) => {
  if (!services || services.length === 0) {
    return null;
  }
  
  return (
    <div className="xvia-recommended-services">
      <Container className="xvia-recommended-services__container">
        <BreakPage text={"SERVIÇOS PARA VOCÊ"} color={"#0661A7"} />
        <Carrocel
          slidesToShow={
            services?.length && services?.length > 4 ? 4 : services?.length
          }
        >
          {!!services &&
            services.map((service, index) => (
              <div key={index} style={{ padding: "0 10px" }}>
                <ServiceCard
                  title={service.title}
                  categoryIcon={service.categoryIcon}
                  onClickFavourite={service.onClickFavourite}
                  rating={service.rating}
                  type={service.type}
                  imageUrl={service.imageUrl}
                  targets={service.targets}
                  link={service.link}
                />
              </div>
            ))}
        </Carrocel>
        {!services && (
          <div className="xvia-recommended-services__loading">
            <Spin />
          </div>
        )}
      </Container>
    </div>
  );
};
